import Display from "../../../methods/displayItems/Display";
import EveryThingView from "../../../views/everyThingView/EveryThingView";
import classes from "./EveryThing.module.css";
export default function EveryThing(params) {
  const itemList = [
    { id: 1, title: "01", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { id: 2, title: "02", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { id: 3, title: "03", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
    { id: 4, title: "04", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." },
  ];
  return (
    <div className={classes.everyThing}>
      <h4>EVERYTHING YOU NEED TO KNOW</h4>

      <div className={classes.boxContainer}>
        <Display itemList={itemList} View={EveryThingView} center />
      </div>
    </div>
  );
}
