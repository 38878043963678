import classes from "./ProductShow.module.css";
import pic01 from "./assets/01.png";
import pic02 from "./assets/02.png";
import pic03 from "./assets/03.png";
import pic04 from "./assets/04.jpg";
import ProductSlide from "../../../methods/ReactSlick/productSlide/ProductSlide";
import ProductSlideView from "../../../views/productSlideView/ProductSlideView";

export default function ProductShow(params) {
  const itemList = [
    { id: 1, image: pic01, title: "Product Name", details: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" },
    { id: 2, image: pic02, title: "Product Name", details: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" },
    { id: 3, image: pic03, title: "Product Name", details: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" },
    { id: 4, image: pic04, title: "Product Name", details: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour" },
  ];
  return (
    <div className={classes.productShow}>
      <ProductSlide View={ProductSlideView} itemList={itemList} />
    </div>
  );
}
